import { handleErrorWithSentry, Replay } from "@sentry/sveltekit";
import { PUBLIC_ENV } from "$env/static/public";
import * as Sentry from "@sentry/sveltekit";
import { setUpZodI18nMap } from "$lib/schemas/setup";
import { CaptureConsole } from "@sentry/integrations";

setUpZodI18nMap();

if (["production", "staging"].includes(PUBLIC_ENV)) {
  Sentry.init({
    dsn: "https://34015bd16b374ec59692efbc19217e04@o309621.ingest.sentry.io/4505185740914688",
    environment: PUBLIC_ENV,
    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // If you don't want to use Session Replay, just remove the line below:
    integrations: [new Replay(), new CaptureConsole({ levels: ["error", "warn"] })],
  });
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
