import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46')
];

export const server_loads = [0,4];

export const dictionary = {
		"/": [~5],
		"/action": [~6,[2]],
		"/action/c": [9,[2]],
		"/action/c/customquery": [10],
		"/action/c/customquery/add": [12],
		"/action/c/customquery/[slug]/edit": [~11],
		"/action/e": [13,[2]],
		"/action/e/customquery": [14],
		"/action/e/customquery/add": [16],
		"/action/e/customquery/[slug]/edit": [~15],
		"/action/g": [17,[2]],
		"/action/g/customquery": [18],
		"/action/g/customquery/add": [20],
		"/action/g/customquery/[slug]/edit": [~19],
		"/action/h": [21,[2]],
		"/action/h/customquery": [22],
		"/action/h/customquery/add": [24],
		"/action/h/customquery/[slug]/edit": [~23],
		"/action/[slug]": [~7],
		"/action/[slug]/edit": [~8],
		"/admin": [~25,[3]],
		"/admin/supervisor": [26,[3]],
		"/all": [27],
		"/in": [~28,[4]],
		"/in/add": [31,[4]],
		"/in/customquery": [32,[4]],
		"/in/customquery/add": [34,[4]],
		"/in/customquery/[slug]/edit": [~33,[4]],
		"/in/[slug]": [~29,[4]],
		"/in/[slug]/edit": [~30,[4]],
		"/login": [35],
		"/logout": [36],
		"/notification": [37],
		"/out": [38],
		"/resources/email-templates": [39],
		"/sentry-example": [40],
		"/web": [~41],
		"/web/customquery": [44],
		"/web/customquery/add": [46],
		"/web/customquery/[slug]/edit": [~45],
		"/web/[slug]": [~42],
		"/web/[slug]/edit": [~43]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';